import {
    AdditionalWidgetProperties,
    useRefinementList,
    UseRefinementListProps,
} from 'react-instantsearch';
import { useDeferredFunction } from '~/shared/hooks/useDeferredFunction';
import { useDeferredValue } from 'react';

const useOptimizedRefinementList = (
    props: UseRefinementListProps,
    additionalWidgetProperties?: AdditionalWidgetProperties,
) => {
    const limit = 5;
    const showMoreLimit = 1000;
    const { items, refine, ...rest } = useRefinementList(
        {
            escapeFacetValues: false,
            limit,
            showMoreLimit,
            ...props,
        },
        additionalWidgetProperties,
    );

    const deferredItems = useDeferredValue(items);
    const deferredRefine = useDeferredFunction(refine);

    return {
        ...rest,
        items: deferredItems,
        refine: deferredRefine,
        isPending: deferredItems !== items,
        limit,
    };
};
export default useOptimizedRefinementList;
