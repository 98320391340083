export const FACET_TYPES = {
    STORE: 'cust_storeNamesWithStock',
    STORE_WEB: 'cust_in_stock_web',
    OFFERS: 'cust_onOffer.isOnOffer',
    MEMBERS_OFFER: 'cust_onOffer.isMemberOffer',
    PRICE_RANGE: 'cust_priceRanges',
    EFFECTIVE_PRICE: 'cust_effectivePrice',
    SOLE_REPRESENTATION: 'cust_sole_representation',
    IS_NEW: 'cust_isNew',
    ALLERGY_FRIENDLY: 'cust_spec_allergyFriendly',
    PERSONALIZATION: 'cust_spec_personalization',
    COLOR: 'cust_color',
};
