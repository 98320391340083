'use client';

import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { useRawHtml } from './hooks';

export interface RawHtmlProps
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    html?: string;
    shouldSanitizeHtml?: boolean;
}

export const RawHtml = ({ html = '', shouldSanitizeHtml, ...rest }: RawHtmlProps) => {
    return useRawHtml(html, shouldSanitizeHtml, rest);
};
