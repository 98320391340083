import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { cloudflareLoader } from '~/shared/components/Image/loaders/cloudflareLoader';
import { usePage } from '../../hooks/usePage';
import { useConfig } from '~/shared/hooks';

export const MetaFields = () => {
    const { meta, type, data } = usePage();

    const { title, description, canonical, hideFromRobots, openGraph, url } = meta || {};
    const seoDescription = data?.product?.pageDescription;
    const variantDescription = data?.product?.shortDescription;

    //Meta description to render
    const metaDescription = seoDescription || variantDescription || description;

    // if page type is p50ProductDetailsPage then point canonical to its own url.
    const canonicalUrl = type === 'p50ProductDetailsPage' ? url : canonical?.url;

    const { query } = useRouter();
    const { config } = useConfig();
    const imageWidth = 1200;
    const imageUrl = openGraph?.image?.src
        ? cloudflareLoader({ src: openGraph?.image.src, width: imageWidth })
        : null;
    const calculatedCanonical =
        config.NEXT_PUBLIC_URL + (canonicalUrl || getDefaultCanonical(query));

    const calculatedUrl = config.NEXT_PUBLIC_URL + (url || getDefaultCanonical(query));

    return (
        <Head>
            {title ? <title key="title">{title}</title> : null}
            {metaDescription ? (
                <meta key="description" name="description" content={metaDescription} />
            ) : null}
            <meta name="name" content={title} />
            {hideFromRobots ? (
                <meta key="hideFromRobots" name="robots" content="noindex, nofollow" />
            ) : null}
            <link key="canonical" rel="canonical" href={calculatedCanonical} />
            <meta name="og:url" content={calculatedUrl} />
            <meta name="og:site_name" content="Imerco" />
            <meta name="author" content="Imerco" />
            <meta name="copyright" content="Imerco" />
            {!!imageUrl && !!openGraph?.image && (
                <>
                    <meta name="og:image" content={imageUrl} />
                    <meta name="og:image:width" content={imageWidth + 'px'} />
                    <meta
                        name="og:image:height"
                        content={
                            openGraph.image.height * (imageWidth / openGraph.image.width) + 'px'
                        }
                    />
                </>
            )}
            {!!openGraph?.title && (
                <>
                    <meta name="og:title" content={openGraph?.title} />
                </>
            )}
            {!!openGraph?.description && (
                <>
                    <meta name="og:description" content={openGraph?.description} />
                </>
            )}
            {!!openGraph?.type && (
                <>
                    <meta name="og:type" content={openGraph?.type} />
                    <meta name="twitter_card" content={openGraph?.type} />
                    <meta property="og:type" content={openGraph?.type} />
                </>
            )}
            {!!openGraph &&
                Object.entries(openGraph)
                    .filter((x) => !['image', 'title', 'description', 'type'].includes(x[0]))
                    .map(([key, value]) => (
                        <meta key={key} property={`og:${key}`} content={value as string} />
                    ))}

            <meta name="robots" content="max-image-preview:large" />
        </Head>
    );
};

function getDefaultCanonical(query: ParsedUrlQuery) {
    const pathname = Array.isArray(query.slug) ? query.slug.join('/') : query.slug ?? '';
    const params = new URLSearchParams();

    if (query.id) {
        params.append('id', query.id.toString());
    }
    if (query.storeid) {
        params.append('storeid', query.storeid.toString());
    }
    if (query.page && parseInt(query.page.toString(), 10) > 1) {
        params.append('page', query.page.toString());
    }

    const result = `/${pathname?.toLowerCase()}${params.toString() ? `?${params.toString()}` : ''}`;
    return result;
}
