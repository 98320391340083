import { useEffect, useRef, useState } from 'react';
import { usePage } from '~/templates/pages';
import { breakpointsRaw } from '~/theme/breakpoints';

export function useIsMobile({ width = breakpointsRaw.md } = {}) {
    const { isMobile } = usePage();
    const [isMobileState, setIsMobile] = useState(typeof isMobile === 'boolean' ? isMobile : false);
    const resizeEventRef = useRef(false);

    useEffect(() => {
        function handleResize() {
            const isBelowBreakpoint = global.window.innerWidth < width;

            setIsMobile(isBelowBreakpoint);
        }

        if (!resizeEventRef.current) {
            global.window.addEventListener('resize', handleResize);
            resizeEventRef.current = true;
        }

        handleResize();

        return () => {
            if (resizeEventRef.current) {
                global.window.removeEventListener('resize', handleResize);
                resizeEventRef.current = false;
            }
        };
    }, []);

    return isMobileState;
}
