import React, { useMemo } from 'react';
import SelectedFacets from './components/FilterBar/SelectedFacets/SelectedFacets';
import FilterDrawer from './components/FilterDrawer/FilterDrawer';
import { FilterBar } from './components/FilterBar/FilterBar';
import Grid from '~/shared/components/Grid';
import { mq } from '~/lib/helpers/media-query.helper';
import { SSRPagination } from './components/Pagination/SSRPagination';
import { EventIdentifier } from '~/shared/hooks/useEvents';
import { Configure, Index } from 'react-instantsearch';
import InfiniteHits from './components/InfiniteHits/InfiniteHits';
import { useIsMobile } from '~/shared/hooks/useIsMobile/useIsMobile';
import FilterList from './components/FilterList/FilterList';
import type { ParsedUrlQuery } from 'querystring';
import { MainAlgoliaIndex } from '~/lib/algolia';

type Props = {
    hitsPerPage?: number;
    filters?: string;
    identifier: EventIdentifier;
    configureProps?: Record<string, any>;
    serverUrl?: string;
    query?: ParsedUrlQuery;
};

const ProductsLoader = ({
    hitsPerPage = 20,
    identifier,
    configureProps,
    serverUrl,
    query,
}: Props) => {
    const isMobile = useIsMobile();
    // hide facets on Gavekort PLP as they have only to distinct products
    const shouldHideFacets = useMemo(
        () => configureProps?.ruleContexts?.map((x: any) => x.toLowerCase()).includes('gavekort'),
        [configureProps?.ruleContexts],
    );

    return (
        <>
            <Configure {...configureProps} hitsPerPage={hitsPerPage} clickAnalytics />

            {isMobile && <FilterDrawer />}

            {shouldHideFacets === false && (
                <FilterBar>
                    <FilterList />
                    <SelectedFacets />
                </FilterBar>
            )}

            <Index indexName={MainAlgoliaIndex} indexId="products">
                <InfiniteHits
                    identifier={identifier}
                    query={query?.query}
                    itemsPerpage={hitsPerPage}
                />
            </Index>

            <FilterBar>
                <Grid
                    breakpoints={{
                        [mq('xs')]: {
                            flow: 'row',
                            rowGap: 'none',
                            columnGap: 'none',
                            columns: '1fr',
                        },
                        [mq('sm', 'md')]: {
                            columns: '1fr 1fr',
                            columnGap: 'small',
                            rowGap: 'small',
                        },
                        [mq('md', 'lg')]: {
                            columns: 'repeat(3, 1fr)',
                            columnGap: 'medium',
                            rowGap: 'medium',
                        },
                        [mq('lg')]: {
                            columns: 'repeat(4, 1fr)',
                            columnGap: 'medium',
                            rowGap: 'medium',
                        },
                    }}
                    columnGap="medium"
                    flow="column"
                />
            </FilterBar>

            <SSRPagination asPath={serverUrl} />
        </>
    );
};

export default ProductsLoader;
