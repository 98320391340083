import React, { HTMLAttributes, useMemo, DetailedHTMLProps } from 'react';
import parse, {
    attributesToProps,
    domToReact,
    Element,
    HTMLReactParserOptions,
} from 'html-react-parser';
import sanitize from 'sanitize-html';
import { Link, Text } from '$shared/components';
import { TextVariants } from '~/theme/text-styles';
import { StyledRichText, StyledTabel } from '../styled';

type RegularTextTags = (typeof regularTextTags)[number];
const regularTextTags = [
    'p',
    'b',
    'strong',
    'i',
    'em',
    'u',
    'blockquote',
    'code',
    'pre',
    'li',
] as const;

const options: HTMLReactParserOptions = {
    /**
     * Required to prevent warning about whitespace in tables - if tables are used.
     * Might removed intended whitespace.
     *
     * @see https://github.com/remarkablemark/html-react-parser#trim
     */
    trim: true,

    /**
     * Replace HTML tags with react components
     * @see https://github.com/remarkablemark/html-react-parser#replace-element-attributes
     */
    replace: (domNode) => {
        const { attribs, children, name } = domNode as Element;
        const props = attributesToProps(attribs || {});

        if (!attribs) {
            return;
        }

        if (regularTextTags.includes(name as RegularTextTags)) {
            const tag = name as RegularTextTags;
            return <Text {...props} as={tag} children={domToReact(children, options)} />;
        }

        if (
            ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(
                name as 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6',
            )
        ) {
            const size = Number(name.replace(/\D/g, ''));
            //TODO: Let the user choose variant from Richtext
            return (
                <Text
                    variant={`display${size}` as TextVariants}
                    {...props}
                    as={'h1'}
                    children={domToReact(children, options)}
                />
            );
        }

        if (name === 'a') {
            return <Link href={props.href} {...props} children={domToReact(children, options)} />;
        }

        if (name === 'table') {
            return <StyledTabel {...props} children={domToReact(children, options)} />;
        }
    },
};

export const useRawHtml = (
    html: string,
    shouldSanitizeHtml?: boolean,
    props?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
) => {
    const sanitizedHtml = useMemo(() => {
        if (!shouldSanitizeHtml) return html;

        const anySafeString = [/^(?!.*(?:expression|script|behavior|url)).*$/i];

        return sanitize(html, {
            // Allow commonly used CSS properties to style text.
            allowedStyles: {
                '*': {
                    color: anySafeString,
                    padding: anySafeString,
                    margin: anySafeString,
                    'list-style-type': anySafeString,
                    'text-align': anySafeString,
                    'text-decoration': anySafeString,
                    'text-shadow': anySafeString,
                    'font-weight': anySafeString,
                    'font-style': anySafeString,
                },
            },
            allowedAttributes: {
                ...sanitize.defaults.allowedAttributes,
                '*': ['style'],
            },
        });
    }, [html]);

    const parsedHtml = useMemo(() => parse(sanitizedHtml, options), [sanitizedHtml]);

    return <StyledRichText {...props}>{parsedHtml}</StyledRichText>;
};
