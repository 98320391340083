import React from 'react';
import useSwiperRef from './hooks/useSwiperRef';
import { StyledCarousel } from './styled';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, SwiperOptions, Navigation, Grid } from 'swiper';
import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/navigation';
import 'swiper/css/grid';

export interface CarouselProps {
    /**
     * A list of items to be shown in a slide
     */
    items?: React.ReactNode[];
    prevRef?: React.RefObject<HTMLButtonElement>;
    nextRef?: React.RefObject<HTMLButtonElement>;
    options?: SwiperOptions;
}
export const Carousel = ({
    items,
    prevRef: initialPrevRef,
    nextRef: initialNextRef,
    options = {},
}: CarouselProps) => {
    const { element: prevEl } = useSwiperRef({ ref: initialPrevRef });
    const { element: nextEl } = useSwiperRef({ ref: initialNextRef });

    const config: SwiperOptions = {
        slidesPerView: 'auto',
        watchSlidesProgress: true,

        watchOverflow: true,
        a11y: {
            slideRole: 'listitem',
        },
        navigation: {
            prevEl,
            nextEl,
        },
        modules: [A11y, Navigation, Grid],
        updateOnWindowResize: true,
        ...options,
    };

    return (
        <StyledCarousel>
            <Swiper role="list" aria-busy="true" {...config}>
                {items?.map((item, index) => {
                    return (
                        <SwiperSlide data-testid="category-link" key={index}>
                            {item}
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </StyledCarousel>
    );
};
