import React, { FC, memo, useCallback, useState } from 'react';
import { useEvents } from '~/shared/hooks/useEvents';
import { useFrame } from '~/shared/utils';
import { useTranslation } from '~/shared/utils/translation';
import IRefinementList from './RefinementList.definitions';
import {
    StyledCheckmark,
    StyledCheckmarkContainer,
    StyledContainer,
    StyledList,
    StyledListItem,
    StyledListTextWrapper,
    StyledListValueWrapper,
    StyleListInput,
} from './RefinementList.styled';
import { useDebounce } from 'react-use';
import { RefinementListItem } from '~/features/productList/types';
import useOptimizedRefinementList from '~/shared/hooks/useOptimizedRefinementList';
import RefinementEmptyList from '../../FilterList/components/RefinementEmptyList';
import { FilterDropdownExpand } from '../../FilterList/components/FilterDropdownExpand';
import { useRefinementList } from 'react-instantsearch';

interface RefinementListItemsProps {
    items: RefinementListItem[];
    shouldShowCount?: boolean;
    onRefine(value: string): void;
}

const RefinementListItems: FC<RefinementListItemsProps> = ({
    items,
    shouldShowCount,
    onRefine,
}) => {
    const { translate } = useTranslation();

    const getLabel = useCallback(
        (label: string) => {
            switch (label) {
                case 'true':
                    return translate('pdp.spec.true');
                case 'false':
                    return translate('pdp.spec.false');
                default:
                    return label;
            }
        },
        [translate],
    );

    return (
        <StyledList column>
            {items.map(({ label, value, isRefined, count }) => (
                <StyledListItem
                    key={`${label}-${value}`}
                    isRefined={isRefined}
                    onClick={() => onRefine(value)}
                >
                    <StyledListTextWrapper> {getLabel(label)}</StyledListTextWrapper>

                    <StyledListValueWrapper>
                        {shouldShowCount && count ? ` (${count})` : ''}
                        <StyledCheckmarkContainer isRefined={isRefined}>
                            {isRefined && <StyledCheckmark isRefined={isRefined} />}
                        </StyledCheckmarkContainer>
                    </StyledListValueWrapper>
                </StyledListItem>
            ))}
        </StyledList>
    );
};

const MemoizedRefinementListItems = memo(RefinementListItems);

export const RefinementList: FC<IRefinementList> = ({ attribute, searchable, showCount }) => {
    const { data: frame } = useFrame();
    const { translate } = useTranslation();
    const { filterEvent } = useEvents(frame);
    const [searchValue, setSearchValue] = useState('');

    const { refine, searchForItems, items, isShowingMore, toggleShowMore, limit } =
        useOptimizedRefinementList({
            attribute,
            escapeFacetValues: false,
        });

    const { items: allItems } = useRefinementList({ limit: 1000, attribute });

    const handleRefine = useCallback((value: string) => {
        if (!value) return;

        filterEvent('Filtering', value.toString());

        refine(value);
    }, []);

    useDebounce(
        () => {
            searchForItems(searchValue ?? '');
        },
        200,
        [searchValue],
    );

    const showMoreFilterCount = Math.max(0, allItems.length - limit);
    const shouldShowExpandButton = allItems.length >= limit;

    return (
        <StyledContainer>
            {searchable && (
                <StyleListInput
                    isEmpty={items && items.length === 0}
                    placeholder={translate('plp.searchPlaceholder')}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                />
            )}

            <MemoizedRefinementListItems
                items={items}
                shouldShowCount={showCount}
                onRefine={handleRefine}
            />

            {shouldShowExpandButton && (
                <FilterDropdownExpand
                    onClick={toggleShowMore}
                    isExpanded={isShowingMore}
                    filterCount={showMoreFilterCount}
                />
            )}

            {items && items.length === 0 && <RefinementEmptyList />}
        </StyledContainer>
    );
};

export default RefinementList;
