import React, { FC, forwardRef, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useMeasure } from 'react-use';
import RefinementList from '../RefinementList/RefinementList';
import RefinementListStock from '../RefinementList/RefinementListStock';
import RefinementListToggle from '../RefinementList/RefinementListToggles';
import IRefinementAccordion from './RefinementAccordion.definitions';
import { preDefinedAttributes } from '../FilterDrawer';
import {
    StyledAccordion,
    StyledAccordionContent,
    StyledAccordionFacetContainer,
    StyledAccordionHeader,
    StyledChevron,
} from './RefinementAccordion.styled';
import RefinementListColor from '../RefinementList/RefinementListColor';
import { colorSwatchKey } from '~/features/productList/hooks/useColorSwatches';
import { TranslationKey } from '~/lib';
import { useTranslation } from '~/shared/utils/translation';
import { useCurrentRefinements } from 'react-instantsearch';
import { RefinementPrice } from '../../FilterList/components/RefinementPrice';
import { FACET_TYPES } from '~/features/productList/constants';

const RefinementAccordion: FC<IRefinementAccordion> = forwardRef<
    HTMLDivElement,
    IRefinementAccordion
>(({ attribute, isParentOpen, handleOpenToggle }, accordionRef) => {
    const { translate } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [contentContainerRef, { height: contentContainerHeight }] = useMeasure<HTMLDivElement>();
    const { items: currentRefinements } = useCurrentRefinements();

    const attributeLabel = useMemo(() => {
        if (attribute === preDefinedAttributes.newsToggles) {
            return {
                label: translate('plp.facet.campaigns'),
                attribute: [FACET_TYPES.OFFERS, FACET_TYPES.MEMBERS_OFFER],
            };
        }

        if (attribute === preDefinedAttributes.stockStatus) {
            return {
                label: translate('plp.facet.cust_storeIdsWithStock'),
                attribute: [FACET_TYPES.STORE, FACET_TYPES.STORE_WEB],
            };
        }

        if ([FACET_TYPES.SOLE_REPRESENTATION, FACET_TYPES.IS_NEW].includes(attribute)) {
            return {
                label: translate(`plp.facet.${attribute}` as TranslationKey),
                attribute,
            };
        }

        if (FACET_TYPES.PRICE_RANGE === attribute) {
            return {
                label: translate(`plp.facet.${attribute}` as TranslationKey),
                attribute: [FACET_TYPES.PRICE_RANGE, FACET_TYPES.EFFECTIVE_PRICE],
            };
        }

        return {
            label: translate(`plp.facet.${attribute}` as TranslationKey),
            attribute,
        };
    }, [attribute, translate]);

    const attributeOptions = useMemo(() => {
        switch (attribute) {
            case preDefinedAttributes.newsToggles:
                return (
                    <RefinementListToggle
                        attributes={[
                            { attribute: FACET_TYPES.OFFERS, on: true },
                            { attribute: FACET_TYPES.MEMBERS_OFFER, on: true },
                            // { attribute: 'cust_activepromo.promotiontype', on: 'Julepris' },
                        ]}
                    />
                );

            case colorSwatchKey:
                return null;

            case preDefinedAttributes.stockStatus:
                return <RefinementListStock />;

            case FACET_TYPES.SOLE_REPRESENTATION:
            case FACET_TYPES.IS_NEW:
            case FACET_TYPES.ALLERGY_FRIENDLY:
            case FACET_TYPES.PERSONALIZATION:
                return <RefinementListToggle attributes={[{ attribute, on: true }]} />;

            case FACET_TYPES.PRICE_RANGE:
                return (
                    <>
                        <RefinementPrice attribute={FACET_TYPES.EFFECTIVE_PRICE} />
                        <RefinementList showCount attribute={attribute} />
                    </>
                );

            case FACET_TYPES.COLOR:
                return <RefinementListColor attribute={attribute} searchable showCount />;

            default:
                return <RefinementList attribute={attribute} searchable showCount />;
        }
    }, [attribute]);

    const handleToggle = () => {
        setIsOpen(!isOpen);
        handleOpenToggle?.(attribute, !isOpen);
    };

    useEffect(() => {
        if (!isParentOpen) setIsOpen(false);
    }, [isParentOpen]);

    const tilbudFacets = [FACET_TYPES.MEMBERS_OFFER, FACET_TYPES.OFFERS];
    const storeFacets = [FACET_TYPES.STORE_WEB, FACET_TYPES.STORE];
    const priceRangeFacets = [FACET_TYPES.PRICE_RANGE, FACET_TYPES.EFFECTIVE_PRICE];

    const filterFacets = useCallback(
        (facets: string | string[]) =>
            currentRefinements
                .filter((item) => facets.includes(item.attribute))
                .map((item) => item.attribute),
        [currentRefinements],
    );

    const selectedFacetsTilbud = useMemo(() => filterFacets(tilbudFacets), [currentRefinements]);
    const selectedStoreFacets = useMemo(() => filterFacets(storeFacets), [currentRefinements]);
    const selectedPriceRangeFacets = useMemo(
        () => filterFacets(priceRangeFacets),
        [currentRefinements],
    );

    const selectedFacets = useMemo(
        () => currentRefinements.find((item) => attributeLabel.attribute.includes(item.attribute)),
        [currentRefinements, attributeLabel],
    );

    const arraysMatch = (array1: string[], array2: string[]) =>
        array2.every((value) => array1.includes(value));

    const storeCount = useMemo(
        () => currentRefinements.find((item) => item.attribute === FACET_TYPES.STORE),
        [currentRefinements],
    );

    const priceRangeFacetsCount = useMemo(
        () => currentRefinements.find((item) => item.attribute === FACET_TYPES.PRICE_RANGE),
        [currentRefinements],
    );

    const selectedFacetsCount = useMemo(() => {
        if (!selectedFacets || !attributeLabel.attribute.includes(selectedFacets.attribute)) {
            return null;
        }

        const getFacetCount = (count: number) =>
            `(${translate('plp.selectedFacets', { count: `${count}` })})`;

        // Conditions for mapping the counts
        const matchConditions = [
            //if instock facet toggle is selected
            {
                condition: storeCount && arraysMatch(selectedStoreFacets, storeFacets),
                attributes: storeFacets,
                count: (storeCount?.refinements?.length ?? 0) + 1,
            },
            //if both facet of tilbud is selected
            {
                condition: selectedFacetsTilbud.length > 0,
                attributes: tilbudFacets,
                count: selectedFacetsTilbud.length,
            },
            //if price range has value in the range input and has selected facet
            {
                condition:
                    priceRangeFacetsCount &&
                    arraysMatch(selectedPriceRangeFacets, priceRangeFacets),
                attributes: priceRangeFacets,
                count: (priceRangeFacetsCount?.refinements?.length ?? 0) + 1,
            },
            //if only effective price has value
            {
                condition: selectedFacets.attribute.includes(FACET_TYPES.EFFECTIVE_PRICE),
                count: 1,
            },
        ];

        // Find and return the first matching condition's facet count
        for (const { condition, attributes, count } of matchConditions) {
            if (
                condition &&
                (!attributes || attributes.some((attr) => selectedFacets.attribute.includes(attr)))
            ) {
                return getFacetCount(count);
            }
        }

        // Default facet count
        return getFacetCount(selectedFacets.refinements.length);
    }, [
        selectedFacets,
        attributeLabel,
        tilbudFacets,
        storeFacets,
        selectedFacetsTilbud,
        selectedStoreFacets,
        storeCount,
    ]);

    return (
        <StyledAccordion ref={accordionRef}>
            <StyledAccordionHeader
                hasFacetSelected={
                    currentRefinements.some((item) =>
                        attributeLabel.attribute.includes(item.attribute),
                    ) && !isOpen
                }
                hasSelected={isOpen}
                onClick={handleToggle}
            >
                {attributeLabel.label}

                <StyledAccordionFacetContainer>
                    {!isOpen ? selectedFacetsCount : null}
                    <StyledChevron isOpen={isOpen} />
                </StyledAccordionFacetContainer>
            </StyledAccordionHeader>

            <StyledAccordionContent style={{ height: isOpen ? contentContainerHeight : 0 }}>
                <div ref={contentContainerRef}>{attributeOptions}</div>
            </StyledAccordionContent>
        </StyledAccordion>
    );
});

RefinementAccordion.displayName = 'RefinementAccordion';

export default memo(RefinementAccordion);
