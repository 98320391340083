import React from 'react';
import { Image } from '~/shared/components/Image';
import Plus from '$icons/plus.svg';
import { weakKey } from '~/shared/utils/jsx';
import styles from './swatches.module.scss';

type Props = {
    swatchesOptions?: {
        color: string;
        alt: string;
        url: string;
        title: string;
    }[];
};

export const Swatches = (props: Props) => {
    const { swatchesOptions } = props;
    return (
        <div className={styles.swatchesContainer}>
            {Array.isArray(swatchesOptions) &&
                swatchesOptions.slice(0, 3)?.map((item) => {
                    return (
                        <div className={styles.swatchItem} key={weakKey(item)}>
                            <Image height={20} width={20} alt={item.alt} src={item.url} />
                        </div>
                    );
                })}
            {Array.isArray(swatchesOptions) && swatchesOptions?.length > 3 && (
                <Plus className={styles.swatchMore} />
            )}
        </div>
    );
};
